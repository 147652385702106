import "focus-visible-polyfill"

import "@cloudflare/cloudflare-brand-assets/css/global/box-sizing.css"
import "@cloudflare/cloudflare-brand-assets/css/global/element-normalization.css"
import "@cloudflare/cloudflare-brand-assets/css/global/sizes-variables.css"
import "@cloudflare/cloudflare-brand-assets/css/global/font-variables.css"
import "@cloudflare/cloudflare-brand-assets/css/global/brand-color-variables.css"
import "@cloudflare/cloudflare-brand-assets/css/global/theme-color-variables.css"
import "@cloudflare/cloudflare-brand-assets/css/global/theme-helpers.css"
import "@cloudflare/cloudflare-brand-assets/css/global/selection-color.css"
import "@cloudflare/cloudflare-brand-assets/css/global/html.css"

import "@cloudflare/cloudflare-brand-assets/css/helpers/desktop-and-mobile-only.css"
import "@cloudflare/cloudflare-brand-assets/css/helpers/is-smooth-scrolling.css"
import "@cloudflare/cloudflare-brand-assets/css/helpers/is-visually-hidden.css"
import "@cloudflare/cloudflare-brand-assets/css/helpers/with-styled-webkit-scrollbars.css"

import "@cloudflare/cloudflare-brand-assets/css/components/cloudflare-logo.css"
import "@cloudflare/cloudflare-brand-assets/css/components/link.css"
import "@cloudflare/cloudflare-brand-assets/css/components/button.css"
import "@cloudflare/cloudflare-brand-assets/css/components/unordered-list-pages-themed.css"
import "@cloudflare/cloudflare-brand-assets/css/components/inline-code.css"
import "@cloudflare/cloudflare-brand-assets/css/components/number.css"
import "@cloudflare/cloudflare-brand-assets/css/components/footer.css"

import "./src/css/header.css"
import "./src/css/nav.css"
import "./src/css/browser-column.css"
import "./src/css/browser.css"
import "./src/css/cookie.css"
import "./src/css/frameworks-links.css"
import "./src/css/git-tree.css"
import "./src/css/markdown-lite.css"
import "./src/css/section.css"
import "./src/css/section-spacer.css"
import "./src/css/quote-section.css"
import "./src/css/hero-section.css"
import "./src/css/hero-animation.css"
import "./src/css/plans-section.css"
import "./src/css/search.css"

import "./src/css/cloudflare-pages-github-app-install-animations.css"
import "./src/css/collaboration-animation-messages.css"
import "./src/css/cloudflare-benefits-section-content.css"
